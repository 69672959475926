<template lang='pug'>
.animated.fadeIn.role-list
  v-loading(loader = 'customer:roles:fetch')
    .text-center(slot = 'spinner'): i.fa.fa-spinner.fa-pulse.fa-lg
    .card.border-bottom-0(v-for = 'role in sortClassifiers'
                         :key  = 'role.id')
      .card-header(v-b-toggle = 'role.name') {{ classifierByName('roles', role.name.toLowerCase()) ? classifierByName('roles', role.name.toLowerCase()).human : role.name }}
      b-collapse(:id = 'role.name')
        .card-body
          .table-responsive
            table.table.table-hover
              thead: tr
                th ID
                th {{ $t('permission') }}
              tbody: tr(v-for = '(permission, index) in role.rolePermissions.sort()')
                td {{ index }}
                td {{ permission }}
</template>


<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'role-list',

  computed: {
    ...mapState('customers', ['customerRoles']),
    ...mapGetters('classifiers', ['classifierByName']),
    sortClassifiers () {
      const list = JSON.parse(JSON.stringify(this.customerRoles))
      const sortList = list.sort(function (a, b) {
        const nameA = a.name
        const nameB = b.name
        if (nameA < nameB) return -1
        if (nameA > nameB) return 1
        return 0
      })
      return sortList
    }
  },

  created () {
    this.loadCustomerRoles()
  },

  methods: mapActions('customers', ['loadCustomerRoles'])
}
</script>

<i18n>
en:
  permission: "Permission"
et:
  permission: "Permission"
ru:
  permission: "Permission"
</i18n>


<style scoped lang="scss">
.card-header {
  cursor: pointer;
}
</style>
